import React from 'react';
import { Helmet } from 'react-helmet';
import StandardFrame from '../components/StandardFrame';
import PageSection from '../components/Sections/PageSection';
import { Box, Grid, Typography } from '@mui/material';
import ContactCard from '../components/Cards/ContactCard';
import KontaktFormularz from '../sections/kontakt/formularz';

type ContactCardPropsType = {
  title: string;
  phoneNumber: string;
  email: string;
};

const systemPurchaseCardProps: ContactCardPropsType = {
  title: 'Zakup systemu',
  phoneNumber: '+48 61 41 51 810',
  email: 'kontakt@mediporta.pl'
};

const supportCardProps: ContactCardPropsType = {
  title: 'Centrum Wsparcia Mediporta',
  phoneNumber: '+48 61 41 51 810',
  email: 'pomoc@mediporta.pl'
};

export default () => {
  return (
    <>
      <Helmet title='Kontakt' />
      <StandardFrame>
        <PageSection maxWidth='lg' bgcolor='tertiary.main'>
          <Typography
            variant='h2'
            textTransform='uppercase'
            sx={{ fontWeight: 'bold', textAlign: 'center' }}
            gutterBottom
          >
            Kontakt
          </Typography>
          <Typography variant='h5' component={'p'} gutterBottom sx={{ textAlign: 'center' }}>
            Jeśli chcesz skorzystać z naszych usług lub masz pytania, wypełnij poniższy formularz.
          </Typography>
          <Typography variant='h5' component={'p'} gutterBottom sx={{ textAlign: 'center' }}>
            Skontaktujemy się zwrotnie, aby omówić Twoje potrzeby.
          </Typography>
          <Box marginTop={6} marginBottom={1}>
            <KontaktFormularz />
          </Box>
          <PageSection>
            <Grid container spacing={3} justifyContent='center'>
              <ContactCard {...systemPurchaseCardProps} />
              <ContactCard {...supportCardProps} />
            </Grid>
          </PageSection>
        </PageSection>
      </StandardFrame>
    </>
  );
};
